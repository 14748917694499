import React from "react";

import "./Footer.scss";

class Footer extends React.Component {
  render() {
    return (
      <div className="Footer">
        <div className="basetext2">Copyright © 2024 James Park</div>
      </div>
    );
  }
}

export default Footer;
